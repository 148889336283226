import NameCellRenderer from './NameCellRenderer.vue';
import CreatedCellRenderer from './CreatedCellRenderer.vue';
import ActionsCellRenderer from './ActionsCellRenderer.vue';
import DesCellRenderer from './DesCellRenderer.vue';
import TemplateCellRenderer from './TemplateCellRenderer.vue';
import PathCellRenderer from './PathCellRenderer.vue';
import SecurityCellRenderer from './SecurityCellRenderer.vue';
import PhotoCellRenderer from './PhotoCellRenderer.vue';
import DictionaryCellRenderer from './DictionaryCellRenderer.vue'

export {
  NameCellRenderer,
  CreatedCellRenderer,
  ActionsCellRenderer,
  DesCellRenderer,
  TemplateCellRenderer,
  PathCellRenderer,
  SecurityCellRenderer,
  PhotoCellRenderer,
  DictionaryCellRenderer
};
