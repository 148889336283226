//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapGetters('project', ['projectById']),
    projectId() {
      return this.$route.params.projectId;
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    projectDictionary() {
      return this.currentProject?.dictionary || []
    },
    pageDictionary() {
      return this.params.data?.dictionary || []
    },
    usedDictionary() {
      return this.pageDictionary.length > 0 ? this.pageDictionary : this.projectDictionary
    },
    isUsedProjectDictionaryAsDefault() {
      const projectDicString = JSON.stringify([...(this.projectDictionary || [])].sort())
      const usedDictring = JSON.stringify([...(this.usedDictionary || [])].sort())
      return this.projectDictionary.length > 0 && projectDicString == usedDictring
    },
  },
  methods: {
    handleOpenDictionary(dicName) {
      this.$router.push(`/settings/speech-setting?dicName=${dicName}`);
    }
  }
});
